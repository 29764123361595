import React, { useState, useEffect, useRef } from 'react';
import { Card, Button, Row, Col, Table, Modal } from 'react-bootstrap';
import { AiOutlineHome } from "react-icons/ai";

import { mpaasHttpAction, fetchDataActions } from "../../utils/MpaasHttpActionHandler";
import MpaasAdminPanelApi from '../../utils/MpaasAdminPanelApi';
import { ClientDetails, AccountDetails } from "../../models/DataModels";
import dataStorage from '../../dataStorage/dataStorage';
import AccountSummary from "./AccountSummary";
import Validator from "../../utils/Validator";
import Logger from "../../utils/Logger";
import { MpaasConfigService } from '../config/mpaas-web-config';

const AccountDetailsComponent: React.FC = () => {
    const [userEmailId, setUserEmailId] = useState('');
    const [mpaasClientId, setMpaasClientId] = useState(0);
    const [emailInvalidErrorMsg, setEmailInvalidErrorMsg] = useState('');
    const [accountDetailesTableView, setAccountDetailesTableView] = useState(<></>);
    const [accountSummaryInitData, setAccountSummaryInitData] = useState({ isOpen: false, clientDetails: {} });
    const [modalParams, setModalParams] = useState({} as any);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const disableNewMpaasBtnRef: any = useRef(false);

    const [accountDetailsTableData, setAccountDetailsTableData] = useState<AccountDetails[]>([]);

    const toggleNewMpaas = (_mpaasClientId: number, isNewMpaasEnabled: boolean) => {
        disableNewMpaasBtnRef.current = true

        const _request = {
            "mpaasClientId" : _mpaasClientId,
            "newMpaasEnabledFlagStatus" : !isNewMpaasEnabled
        }

        MpaasAdminPanelApi.updateNewMpaasFlag(_request).then((_response: any) => {
            alert("New mpaas setting updated successfully.")

            setAccountDetailsTableData((prev) => {
                prev.map((value) => {
                    if (value.clientDetails.mpaasClientId === _response.mpaasClientId) {
                        value.clientDetails.newMpaasEnabledFlagStatus = _response.newMpaasEnabledFlagStatus
                    }
                })

                return prev
            })

            disableNewMpaasBtnRef.current = false
            _changeAccountDetailsTableView(fetchDataActions.SUCCESS, {});
        }, (error) => {
            Logger.error("Error: ", error)
            alert("Error occured while updating new mpaas setting. Please try again.")
            disableNewMpaasBtnRef.current = false
        })
    }
    
    const renderTableData = () => {
        return (
            <>
                <hr />
                <h5 className="mb-3">Available Account Details</h5>
                <Table bordered responsive className="text-nowrap">
                    <thead className="bg-sky-lighter">
                        <tr>
                            <th>Mettl Client Id</th>
                            <th>Email id</th>
                            <th>Client’s Name</th>
                            <th>Account Status</th>
                            <th>MPaaS Key Generated</th>
                            <th>MPaaS Key Status</th>
                            <th>LTI Key Generated</th>
                            <th>LTI Key Status</th>
                            <th>Organization</th>
                            <th>Account Type</th>
                            <th>Account Manager</th>
                            <th>Last Login</th>
                            <th>Creation Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {accountDetailsTableData.length > 0 && accountDetailsTableData.map((value, key) => {
                            return (
                                <tr key={key}>
                                    <td>{value.clientDetails.mettlClientId}
                                        {value.clientDetails.blockedStatus ?
                                            <Button variant="outline-primary" size="sm" className="ml-4" onClick={() => (openModal(false))}>Activate</Button>
                                            :
                                            <>
                                                <Button variant="outline-primary" size="sm" className="ml-4" onClick={handleSupportLogin}>Login</Button>
                                                <Button variant="outline-danger" size="sm" className="ml-2" onClick={() => (openModal(true))}>Block</Button>
                                            </>
                                        }

                                        <Button variant="outline-primary" size="sm" className="ml-4" disabled={disableNewMpaasBtnRef.current} onClick={() => (toggleNewMpaas(value.clientDetails.mpaasClientId, value.clientDetails.newMpaasEnabledFlagStatus))}>{value.clientDetails.newMpaasEnabledFlagStatus ? "Disable New Mpaas" : "Enable New Mpaas"}</Button>
                                    </td>
                                    <td>
                                        <Button variant="link" className="p-0 align-baseline" onClick={() => { setAccountSummaryInitData({ isOpen: true, clientDetails: value.clientDetails }) }}> {value.clientDetails.emailId} </Button>
                                    </td>
                                    <td>{value.clientDetails.name}</td>
                                    <td className="text-center"><span className={"dot bg-" + (value.clientDetails.accountStatus === 'Active' ? "success" : "danger")}></span> {value.clientDetails.accountStatus}</td>
                                    <td className="text-center"><span className={"dot bg-" + (value.mpsKeys.keysGenerated ? "success" : "danger")}></span> {value.mpsKeys.keysGenerated ? "Yes" : "No"}</td>
                                    <td className="text-center"><span className={"dot bg-" + (value.mpsKeys.enabled ? "success" : "danger")}></span> {value.mpsKeys.enabled ? 'Active' : 'Inactive'}</td>
                                    <td className="text-center"><span className={"dot bg-" + (value.ltiKeys.keysGenerated ? "success" : "danger")}></span> {value.ltiKeys.keysGenerated ? "Yes" : "No"}</td>
                                    <td className="text-center"><span className={"dot bg-" + (value.ltiKeys.enabled ? "success" : "danger")}></span> {value.ltiKeys.enabled ? 'Active' : 'Inactive'}</td>
                                    <td>{value.clientDetails.organization}</td>
                                    <td>{value.clientDetails.accountType}</td>
                                    <td>{value.clientDetails.accountManager}</td>
                                    <td>{value.clientDetails.lastLoginDate}</td>
                                    <td>{value.clientDetails.creationDate}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </>
        )
    }

    const _changeAccountDetailsTableView = (displayView: string, details: object) => {
        switch (displayView) {
            case fetchDataActions.LOADING:
                return setAccountDetailesTableView(mpaasHttpAction.loading());
            case fetchDataActions.SUCCESS:
                return setAccountDetailesTableView(renderTableData);
            case fetchDataActions.ERROR:
                return setAccountDetailesTableView(mpaasHttpAction.error(details));
            default:
                Logger.warn("Invalid account details table view. Display view", displayView);
                return <></>;
        }
    }

    useEffect(() => {
        if (accountDetailsTableData.length > 0) {
            Logger.info("Account Details Table:", accountDetailsTableData);
            _changeAccountDetailsTableView(fetchDataActions.SUCCESS, {});
        }
    }, [accountDetailsTableData])

    const _prepareAndDisplayAccountDetailsTable = () => {
        let clientDetailsData: ClientDetails[] = dataStorage.getClientDetails();
        let mpsKeysData = dataStorage.getMpsKeysData();
        let ltiKeysData = dataStorage.getLtiKeysData();

        for (let i = 0; i < clientDetailsData.length; i++) {
            if (clientDetailsData[i].emailId === mpsKeysData?.emailId && clientDetailsData[i].emailId === ltiKeysData?.emailId) {
                let accountDetails: AccountDetails = {
                    clientDetails: clientDetailsData[i],
                    mpsKeys: mpsKeysData,
                    ltiKeys: ltiKeysData
                }

                setMpaasClientId(clientDetailsData[i].mpaasClientId);
                setAccountDetailsTableData((prev) => ([...prev, accountDetails]))
                break;
            }
        }
    }

    const _checkAndDisplayAccountDetailsTable = () => {
        if (dataStorage.getClientDetails().length < 1 || !dataStorage.getMpsKeysData() || !dataStorage.getLtiKeysData()) {
            Logger.info("Fetching account details data in progress.", null);
            return;
        }

        setIsButtonDisabled(false);
        _prepareAndDisplayAccountDetailsTable();
    }

    const handleSupportLogin = () => {
        _changeAccountDetailsTableView(fetchDataActions.LOADING, {});
        MpaasAdminPanelApi.getLoginKey().then((response: any) => {
            console.log(response);
            if(response.status == "SUCCESS") {
                _changeAccountDetailsTableView(fetchDataActions.SUCCESS, {});
                goToSupportLogin(response);
            }
        }, (error) => {
            console.log(error);
            _changeAccountDetailsTableView(fetchDataActions.ERROR, error)
        });
    }

    const goToSupportLogin = (res: any) => {
        let clientDetailsData: ClientDetails[] = dataStorage.getClientDetails();
        
        let corporateUrl = MpaasConfigService.getConfigMap().mpaasWebCorporateUrl;
        let location = corporateUrl + "/corporate/supportLogin?account_id="+clientDetailsData[0].mettlClientId+"&clientName="+ encodeURIComponent(clientDetailsData[0].encryptedEmailId) +"&clientEmail="+encodeURIComponent(clientDetailsData[0].encryptedOrgnization)+"&firstName="+encodeURIComponent(res.encryptedAdminFirstName)+"&lastName="+encodeURIComponent(res.encryptedAdminLastName)+"&loginToken="+ encodeURIComponent(res.loginToken);
        window.open(location, "_blank");
    }

    const _fetchAccountDetailsDataFromServer = () => {
        _changeAccountDetailsTableView(fetchDataActions.LOADING, {});
        setIsButtonDisabled(true);

        MpaasAdminPanelApi.fetchClientDetails({ emailId: userEmailId }).then((response: any) => {
            dataStorage.setClientDetails(response);
            _checkAndDisplayAccountDetailsTable();
        }, (error) => {
            setIsButtonDisabled(false);
            _changeAccountDetailsTableView(fetchDataActions.ERROR, error)
        });

        MpaasAdminPanelApi.fetchMpsKeysData({ emailId: userEmailId }).then((response: any) => {
            dataStorage.setMpsKeysData(response);
            _checkAndDisplayAccountDetailsTable();
        }, (error) => {
            setIsButtonDisabled(false);
            _changeAccountDetailsTableView(fetchDataActions.ERROR, error);
        });

        MpaasAdminPanelApi.fetchLtiKeysData({ emailId: userEmailId }).then((response: any) => {
            dataStorage.setLtiKeysData(response);
            _checkAndDisplayAccountDetailsTable();
        }, (error) => {
            setIsButtonDisabled(false);
            _changeAccountDetailsTableView(fetchDataActions.ERROR, error);
        });
    }

    const _resetAccountDetailsStoreData = () => {
        setAccountDetailsTableData([]);
        dataStorage.setClientDetails([]);
        dataStorage.setMpsKeysData(null);
        dataStorage.setLtiKeysData(null);
    }

    const openModal = (isBlocked: boolean) => {
        if (isBlocked) {
            setModalParams({
                "title": "Alert",
                "message": "Are you sure you are blocking account",
                "actions": ['Save', 'Cancel'],
                "hideButton": false,
                "isBlocked": true,
                "apiResponse": (<></>)
            });
        } else {
            setModalParams({
                "title": "Alert",
                "message": "Are you sure you are activating",
                "actions": ['Save', 'Cancel'],
                "hideButton": false,
                "isBlocked": false,
                "apiResponse": (<></>)
            });
        }
    }

    const __handleUpdateClientStatusSuccess = (msg: string, response: any) => {
        setModalParams({ ...modalParams, hideButton: false, apiResponse: <span className="text-success">{msg}</span> });

        let cDetails = dataStorage.getClientDetails();
        cDetails[0].blockedStatus = response.blockedStatus;
        dataStorage.setClientDetails(cDetails);
        _prepareAndDisplayAccountDetailsTable();
    }

    const __handleUpdateClientStatusError = (msg: string) => {
        setModalParams({ ...modalParams, hideButton: false, apiResponse: <span className="text-danger">{msg}</span> })
    }

    const blockOrActivateAccount = (isBlocked: boolean) => {
        let data = {
            "mpaasClientId": mpaasClientId,
            "blockedStatus": isBlocked ? true : false
        }

        setModalParams({ ...modalParams, hideButton: true, apiResponse: <span>Saving...</span> });

        MpaasAdminPanelApi.updateClientStatus(data).then((response: any) => {
            if (response.blockedStatus) {
                Logger.info("Account has been successfully blocked. Response:", response);
                __handleUpdateClientStatusSuccess("Account has been successfully blocked.", response);
            } else {
                Logger.info("Account has been successfully activated. Response:", response);
                __handleUpdateClientStatusSuccess("Account has been successfully activated.", response)
            }
        }, (error) => {
            if (isBlocked) {
                mpaasHttpAction.error(error);
                Logger.error("Error while blocking account. Error:", error);
                __handleUpdateClientStatusError("Error while blocking account.");
            } else {
                mpaasHttpAction.error(error);
                Logger.error("Error while activating account. Error:", error);
                __handleUpdateClientStatusError("Error while activating account.");
            }
        });
    }

    const searchAccountDetails = () => {
        setEmailInvalidErrorMsg('');
        let emailValidationData = Validator.validateEmail(userEmailId);
        if (!emailValidationData.isValid) {
            setEmailInvalidErrorMsg(emailValidationData.message);
            Logger.error("Incorrect user email id.", null);
            return;
        }

        _resetAccountDetailsStoreData();
        _fetchAccountDetailsDataFromServer();
    }

    useEffect(() => {
        return () => {
            _resetAccountDetailsStoreData();
        }
      }, []);

    return (
        <>
            {!accountSummaryInitData.isOpen ?
                <div>
                    <AiOutlineHome /> <span className="small"> &gt; View Account Details</span>
                    <br /><br />
                    <h5>View Account Details</h5>
                    <p className="text-muted small">You can view account details based on Users Email ID</p>

                    <Row>
                        <Col lg={5}>
                            <Card className="border-left-4 shadow-sm p-3">
                                <Card.Body className="text-muted font-weight-light p-0 mb-2">Please provide an Email ID</Card.Body>
                                <Row>
                                    <Col lg={9}>
                                        <input type="email" value={userEmailId} onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setUserEmailId(e.target.value) }} className={"w-100 px-2 py-1 border rounded bg-sky-lighter " + (emailInvalidErrorMsg ? 'border-danger' : '')} placeholder="email@email.com" required />
                                        <div className="text-danger pt-2">{emailInvalidErrorMsg}</div>
                                    </Col>
                                    <Col lg={3}>
                                        <Button variant="primary" className="py-1" disabled={isButtonDisabled} onClick={searchAccountDetails}>Search</Button>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>

                    {accountDetailesTableView}
                </div>
                : <AccountSummary initData={accountSummaryInitData} setInitData={setAccountSummaryInitData} />
            }

            <Modal show={Object.keys(modalParams).length > 0} onHide={() => void 0} animation={false}>
                <Modal.Header>
                    <Modal.Title>{modalParams.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalParams.message} {userEmailId} ?</Modal.Body>
                <Modal.Footer>
                    {modalParams.apiResponse}
                    {modalParams.actions && !modalParams.hideButton &&
                        <>
                            <Button variant="primary" onClick={() => { blockOrActivateAccount(modalParams.isBlocked) }}>
                                {modalParams.actions[0]}
                            </Button>
                            <Button variant="outline-primary" onClick={() => { setModalParams({}) }}>
                                {modalParams.actions[1]}
                            </Button>
                        </>
                    }
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AccountDetailsComponent